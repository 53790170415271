import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {PROGRESS_REPORT, PROGRESS_REPORT_API_URL, GET_ALL_PROGRESS_REPORT_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ProgressReportErrors       : {},
  permission: permission?.progress_report ?? [],
  ProgressReportShowMessage  : null,
  ProgressReportResult       : [],
  ProgressReportButtonSpinner : false,
  ProgressReportTableLoading : true,
  ProgressReportAddDrawer    : false,
  ProgressReportEditData     : [],
  ProgressReportButtonAndModelLabel  : setLocale('progressreport.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewProgressReportData: [],
    ViewProgressReportLoader: true,
    DrawerStatus: 0,
};

export const createProgressReport = createAsyncThunk(
    "createProgressReport",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, PROGRESS_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getProgressReport = createAsyncThunk(
    "getProgressReport",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_PROGRESS_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewProgressReport = createAsyncThunk(
  "viewProgressReport",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, PROGRESS_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteProgressReport = createAsyncThunk(
  "deleteProgressReport",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, PROGRESS_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageProgressReportSlice = createSlice({
  name: PROGRESS_REPORT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ProgressReportErrors = {};
    },
    ProgressReportAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.ProgressReportButtonSpinner = false;
      }
      state.ProgressReportAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ProgressReportEditData    = [];
      state.ProgressReportButtonAndModelLabel = setLocale('progressreport.add');
    },
    ProgressReportEditWithDrawerStatus: (state, action) => {
      state.ProgressReportAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ProgressReportEditData = action.payload.data;
      state.ProgressReportButtonAndModelLabel = setLocale('progressreport.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProgressReport.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ProgressReportButtonSpinner = true;
      }).addCase(createProgressReport.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ProgressReportButtonSpinner = false;
        state.ProgressReportErrors = {}
      }).addCase(createProgressReport.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ProgressReportShowMessage = true;
        state.ProgressReportButtonSpinner = false;
        state.ProgressReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getProgressReport.pending, (state, action) => {
        state.ProgressReportButtonSpinner = true;
        state.ProgressReportTableLoading = true;
      }).addCase(getProgressReport.fulfilled, (state, action) => {
        state.ProgressReportButtonSpinner = false;
        state.ProgressReportTableLoading = false;
        state.ProgressReportResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getProgressReport.rejected, (state, action) => {
        state.ProgressReportShowMessage = true; // Set the showMessage flag to display the errors
        state.ProgressReportButtonSpinner = false;
        state.ProgressReportTableLoading = false;
        state.ProgressReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewProgressReport.pending, (state, action) => {
        state.ViewProgressReportLoader = true;
        state.ViewProgressReportData = [];
      }).addCase(viewProgressReport.fulfilled, (state, action) => {
        state.ViewProgressReportLoader = false;
        state.ViewProgressReportData = action.payload;
      }).addCase(viewProgressReport.rejected, (state, action) => {
        state.ViewProgressReportLoader = false;
        state.ViewProgressReportData = [];
      })
      .addCase(deleteProgressReport.pending, (state, action) => {
        state.ProgressReportTableLoading = true;
      }).addCase(deleteProgressReport.fulfilled, (state, action) => {
        state.ProgressReportTableLoading = false;
      }).addCase(deleteProgressReport.rejected, (state, action) => {
        state.ProgressReportTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, ProgressReportAddDrawerStatus, ProgressReportEditWithDrawerStatus, updateSortFilters } = manageProgressReportSlice.actions;

export default manageProgressReportSlice.reducer;
