import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {STUDENT_CONVERSATION, STUDENT_CONVERSATION_API_URL, GET_ALL_STUDENT_CONVERSATION_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  StudentConversationErrors       : {},
  permission: permission?.student_conversation ?? [],
  StudentConversationShowMessage  : null,
  StudentConversationResult       : [],
  StudentConversationButtonSpinner : false,
  StudentConversationTableLoading : true,
  StudentConversationAddDrawer    : false,
  StudentConversationEditData     : [],
  StudentConversationButtonAndModelLabel  : setLocale('studentconversation.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewStudentConversationData: [],
    ViewStudentConversationLoader: true,
};

export const createStudentConversation = createAsyncThunk(
    "createStudentConversation",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, STUDENT_CONVERSATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentConversation = createAsyncThunk(
    "getStudentConversation",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_STUDENT_CONVERSATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewStudentConversation = createAsyncThunk(
  "viewStudentConversation",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, STUDENT_CONVERSATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteStudentConversation = createAsyncThunk(
  "deleteStudentConversation",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, STUDENT_CONVERSATION_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageStudentConversationSlice = createSlice({
  name: STUDENT_CONVERSATION,
  initialState,
  reducers: {
    StudentConversationAddDrawerStatus: (state, action) => {
      state.StudentConversationErrors      = {};
      state.StudentConversationAddDrawer   = action.payload;
      state.StudentConversationEditData    = [];
      state.StudentConversationButtonAndModelLabel = setLocale('studentconversation.add');
    },
    StudentConversationEditWithDrawerStatus: (state, action) => {
      state.StudentConversationErrors = {};
      state.StudentConversationAddDrawer = true;
      state.StudentConversationEditData = action.payload;
      state.StudentConversationButtonAndModelLabel = setLocale('studentconversation.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentConversation.pending, (state, action) => {
        state.StudentConversationButtonSpinner = true;
      }).addCase(createStudentConversation.fulfilled, (state, action) => {
        state.StudentConversationButtonSpinner = false;
        state.StudentConversationErrors = {}
      }).addCase(createStudentConversation.rejected, (state, action) => {
        state.StudentConversationShowMessage = true;
        state.StudentConversationButtonSpinner = false;
        state.StudentConversationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getStudentConversation.pending, (state, action) => {
        state.StudentConversationButtonSpinner = true;
        state.StudentConversationTableLoading = true;
      }).addCase(getStudentConversation.fulfilled, (state, action) => {
        state.StudentConversationButtonSpinner = false;
        state.StudentConversationTableLoading = false;
        state.StudentConversationResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.total,
            current: action.payload.current_page,
            pageSize: action.payload.per_page
        };
      }).addCase(getStudentConversation.rejected, (state, action) => {
        state.StudentConversationShowMessage = true; // Set the showMessage flag to display the errors
        state.StudentConversationButtonSpinner = false;
        state.StudentConversationTableLoading = false;
        state.StudentConversationErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewStudentConversation.pending, (state, action) => {
        state.ViewStudentConversationLoader = true;
        state.ViewStudentConversationData = [];
      }).addCase(viewStudentConversation.fulfilled, (state, action) => {
        state.ViewStudentConversationLoader = false;
        state.ViewStudentConversationData = action.payload;
      }).addCase(viewStudentConversation.rejected, (state, action) => {
        state.ViewStudentConversationLoader = false;
        state.ViewStudentConversationData = [];
      })
      .addCase(deleteStudentConversation.pending, (state, action) => {

      }).addCase(deleteStudentConversation.fulfilled, (state, action) => {

      }).addCase(deleteStudentConversation.rejected, (state, action) => {

      });
  },
});

export const {  StudentConversationAddDrawerStatus, StudentConversationEditWithDrawerStatus, updateSortFilters } = manageStudentConversationSlice.actions;

export default manageStudentConversationSlice.reducer;
