import fetch from 'auth/FetchInterceptor'
const Common = {}
Common.createAllBlogs= function (data, URL) {
	if (data && data.hasOwnProperty('id')) {
	  return fetch({
		url: `${URL}/${data.id}`,
		method: 'put',
		data
	  });
	} else {
	  return fetch({
		url: `${URL}`,
		method: 'post',
		data
	  });
	}
  };
//
Common.getAll= function (data, URL) {
	
	return fetch({
		url: `${URL}`,
		method: 'post',
		data
	})
}
//
Common.showAllBlogs= function (id) {
	
	return fetch({
		url: `${URL}/${id}`,
		method: 'get',
	})
}
//
Common.deleteAllBlogs= function (id) {
    return fetch({
        url: `${URL}/${id}`,
        method: 'delete',
    });
};
export default Common;