import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import {
	AUTH_TOKEN,
} from "constants/AuthConstant";
import { notification } from 'antd';

const unauthorizedCode = [400, 401, 403];

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000
});

// Config
const TOKEN_PAYLOAD_KEY = 'authorization';


// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
	}
	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)

})

// API respone interceptor
service.interceptors.response.use((response) => {

	if (response?.data.success && response.data.message) {
		notification.success({
			message: response.data.message,
			// description: response.data.message
		})
	}

	if (response?.data.warning && response.data.message) {
		notification.warning({
			message: response.data.message,
			// description: response.data.message
		})
	}

	return response.data
}, (error) => {
	let notificationParam = {
		message: ''
	}

	// Remove token and redirect 
	if (unauthorizedCode.includes(error.response.status)) {
		notificationParam.message = 'Authentication Fail'
		// notificationParam.description = 'Please login again'
		localStorage.clear();
		store.dispatch(signOutSuccess())
	}
	if (error?.response?.data?.message) {
		notificationParam.message = error?.response?.data?.message
	}

	if (error.response.status !== 422) {
		notification.error(notificationParam)
	}
	return Promise.reject(error);

});

export default service;
