import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SCHOOL_YEAR, GET_ALL_SCHOOL_YEAR_API_URL, GET_SET_DEFAULT_YEAR_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  SchoolYearErrors: {},
  permission: permission?.school_year ?? [],
  SchoolYearShowMessage: null,
  SchoolYearResult: [],
  SchoolYearButtonSpinner: false,
  SchoolYearTableLoading: true,
  SchoolYearAddDrawer: false,
  SchoolYearEditData: {},
  SchoolYearButtonAndModelLabel: setLocale('school_year.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewSchoolYearData: [],
  ViewSchoolYearLoader: true,
  defaultSchoolYear: null,
  DrawerStatus: 0,
};


export const getSchoolYear = createAsyncThunk(
  "getSchoolYear",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_SCHOOL_YEAR_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const setDefaultYear = createAsyncThunk("setDefaultYear", async (data) => {
  try {
    return await CommonService.getAllPost(data, GET_SET_DEFAULT_YEAR_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
}
);

export const manageSchoolYearSlice = createSlice({
  name: SCHOOL_YEAR,
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      .addCase(setDefaultYear.pending, (state, action) => {
      }).addCase(setDefaultYear.fulfilled, (state, action) => {
        state.defaultSchoolYear = action.payload.data
      }).addCase(setDefaultYear.rejected, (state, action) => {
      })
      .addCase(getSchoolYear.pending, (state, action) => {
        state.SchoolYearButtonSpinner = true;
        state.SchoolYearTableLoading = true;
      }).addCase(getSchoolYear.fulfilled, (state, action) => {
        state.SchoolYearButtonSpinner = false;
        state.SchoolYearTableLoading = false;
        state.SchoolYearResult = action.payload;
        state.defaultSchoolYear = action.payload.data.find(option => option.is_default === 1)?.id
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getSchoolYear.rejected, (state, action) => {
        state.SchoolYearShowMessage = true; // Set the showMessage flag to display the errors
        state.SchoolYearButtonSpinner = false;
        state.SchoolYearTableLoading = false;
        state.SchoolYearErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      });
  },
});

export const { } = manageSchoolYearSlice.actions;

export default manageSchoolYearSlice.reducer;
