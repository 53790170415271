import React, { lazy, Suspense } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LazyLoader = ({ filePath }) => {
  const LazyComponent = lazy(() => import(`${filePath}`));

  return (
    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //   <Suspense fallback={<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}>
    //     <LazyComponent />
    //   </Suspense>
    // </div>
    <div>
    <Suspense>
      <LazyComponent />
    </Suspense>
  </div>
  );
};

export default LazyLoader;
