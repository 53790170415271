import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import {
    ASSIGNMENT_SLICE,
    ASSIGNMENT_API_URL,
    CHANGE_ASSIGNMENT_STATUS,
    MARK_ASSIGNMENT,
    RE_ASSIGN_ASSIGNMENT,
    SAVE_ASSIGNMENT_COMMENT,
    GET_ASSIGNMENT_COMMENTS,
    GET_ASSIGNMENT_TYPES,
    ASSIGNMENT_ATTACHMENT_API_URL
} from 'constants/assignments/index'
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));


export const initialState = {
    loading: false,
    tableLoading: true,
    assignments: null,
    moduleLabel: 'assignment.new',
    editData: null,
    errors: {},
    showMessage: false,
    openModal: false,
    openAssigneeModal: false,
    assigneeModuleLabel: 'assignment.assignees',
    singleAssignment: null,
    singleAssignee: null,
    assigneeLoading: false,
    assignmentPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    obtainMarksLoad: false,
    assignmentComments:null,
    loadingComments:false,
    assignmentTypes:null,
}
export const getAllAssignments = createAsyncThunk('getAllAssignments', async (data) => {
    try {
        return await CommonService.getData(data,ASSIGNMENT_API_URL)        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
})
export const addAssignment = createAsyncThunk('addAssignment', async (data) => {
    try{
        if(data instanceof FormData && data.get('id')){
            return await CommonService.putData(data, ASSIGNMENT_API_URL)
        } else {
           return await CommonService.postData(data, ASSIGNMENT_API_URL)
        }
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }

    // try {
    //     return data && data.hasOwnProperty('id') ? 
    //     await CommonService.putData(data, ASSIGNMENT_API_URL) :
    //     await CommonService.postData(data, ASSIGNMENT_API_URL) 
    // } catch (err) {
    //     throw new Error(JSON.stringify(err.response.data.errors))
    // }
})
export const changeAssignmentStatus = createAsyncThunk('changeAssignmentStatus', async (data) => {
    try {
        return await CommonService.postData(data,CHANGE_ASSIGNMENT_STATUS)        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const markAssignment = createAsyncThunk('markAssignment', async (data) => {
    try {
        return await CommonService.postData(data,MARK_ASSIGNMENT)        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const removeAssignmentFile = createAsyncThunk('removeAssignmentFile', async (data,{dispatch}) => {
    try {
        return await CommonService.deleteData(data,ASSIGNMENT_ATTACHMENT_API_URL);
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
})
export const reAssignAssignment = createAsyncThunk('reAssignAssignment', async (data) => {
    try {
        return await CommonService.postData(data, RE_ASSIGN_ASSIGNMENT)
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const getAssignmentComments = createAsyncThunk('getAssignmentComments', async (data) => {
    try {
        return await CommonService.getData(data, GET_ASSIGNMENT_COMMENTS)
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const saveAssignmentComment = createAsyncThunk('saveAssignmentComment', async (data) => {
    try {
        return await CommonService.postData(data, SAVE_ASSIGNMENT_COMMENT)
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const getAllAssignmentTypes = createAsyncThunk('getAllAssignmentTypes', async (data) => {
    try {
        return await CommonService.getData(data, GET_ASSIGNMENT_TYPES)
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})


export const assignmentSlice = createSlice({
    name: ASSIGNMENT_SLICE,
    initialState,
    reducers: {
        pushNewComment: (state,action) =>{            
            state.assignmentComments = [action.payload, ...state.assignmentComments];
        },
        clearErrors: (state) => {
            state.errors = {};
        },
        setOpenModal: (state) => {
            state.openModal = true
            state.editData = null
            state.moduleLabel = 'assignment.new'
        },
        setCloseModal: (state) => {
            state.openModal = false
            state.editData = null
        },
        setEditData: (state, action) => {
            state.openModal = true
            state.editData = action.payload;
            state.moduleLabel = 'assignment.edit';
        },
        setAssigneeOpenModal: (state) => {
            state.openAssigneeModal = true
            state.assigneeModuleLabel = 'assignment.assignees'
        },
        setAssigneeCloseModal: (state) => {
            state.singleAssignment = null
            state.singleAssignee = null
            state.openAssigneeModal = false
        },
        setSingleAssignment: (state, action) => {
            state.singleAssignment = action.payload
        },
        setSingleAssignee: (state, action) => {
            state.singleAssignee = action.payload
        },
        removeSingleAssignee: (state, action) => {
            state.singleAssignee = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllAssignmentTypes.pending, (state, action) => {
               
            }).addCase(getAllAssignmentTypes.fulfilled, (state, action) => {
                state.assignmentTypes = action.payload.data
            }).addCase(getAllAssignmentTypes.rejected, (state, action) => {
            })
            .addCase(getAllAssignments.pending, (state, action) => {
                state.loading = true
                state.tableLoading = true
            }).addCase(getAllAssignments.fulfilled, (state, action) => {
                state.assignments = action.payload.data;
                state.tableLoading = false
                state.loading = false
                state.assignmentPagination = {
                    ...state.assignmentPagination,
                    total: action.payload.pagination.total,
                    current: action.payload.pagination.current_page,
                    pageSize: action.payload.pagination.per_page
                };
            }).addCase(getAllAssignments.rejected, (state, action) => {
                state.loading = false
                state.tableLoading = false
            })
            .addCase(addAssignment.pending, (state, action) => {
                state.loading = true
                state.tableLoading = true
            }).addCase(addAssignment.fulfilled, (state, action) => {
                state.loading = false
                state.tableLoading = false
                state.errors = {}
                state.showMessage = false
                state.openModal = false
            }).addCase(addAssignment.rejected, (state, action) => {
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message;
                state.showMessage = true
                state.loading = false
                state.tableLoading = false
            })
            .addCase(changeAssignmentStatus.pending, (state, action) => {

            }).addCase(changeAssignmentStatus.fulfilled, (state, action) => {
                state.showMessage = false
            }).addCase(changeAssignmentStatus.rejected, (state, action) => {
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message;
                state.showMessage = true
                state.loading = false
            })
            .addCase(markAssignment.pending, (state, action) => {
                state.obtainMarksLoad = true
            }).addCase(markAssignment.fulfilled, (state, action) => {
                state.showMessage = false
                state.obtainMarksLoad = false
            }).addCase(markAssignment.rejected, (state, action) => {
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message;
                state.showMessage = true
                state.obtainMarksLoad = false
            })
            .addCase(reAssignAssignment.pending, (state, action) => {

            }).addCase(reAssignAssignment.fulfilled, (state, action) => {
                state.showMessage = false
                //::TODO :: Need to more refactor it with Dynamic
                state.singleAssignee.submission_status = 're-assigned'
            }).addCase(reAssignAssignment.rejected, (state, action) => {
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message;
                state.showMessage = true

            })
            .addCase(removeAssignmentFile.pending, (state, action) => {
                state.loading = true
            }).addCase(removeAssignmentFile.fulfilled, (state, action) => {
                state.loading = false
                state.editData.assignmentAttachments = state.editData.assignmentAttachments.filter(attachment => attachment.id !== action.payload);
            }).addCase(removeAssignmentFile.rejected, (state, action) => {
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message;
                state.showMessage = true;
                state.loading = false
            })

            .addCase(saveAssignmentComment.pending, (state, action) => {
                
            }).addCase(saveAssignmentComment.fulfilled, (state, action) => {
                state.loading = false
            }).addCase(saveAssignmentComment.rejected, (state, action) => {
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message;
                state.showMessage = true;
                state.loading = false
            })

            .addCase(getAssignmentComments.pending, (state, action) => {
                state.loadingComments = true
                state.assignmentComments = null
            }).addCase(getAssignmentComments.fulfilled, (state, action) => {
                state.loadingComments = false
                state.assignmentComments = action.payload;
            }).addCase(getAssignmentComments.rejected, (state, action) => {
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message;
                state.showMessage = true;
                state.loadingComments = false
            })
    },
})

export const {
    setOpenModal,
    setCloseModal,
    clearErrors,
    setEditData,
    setAssigneeOpenModal,
    setAssigneeCloseModal,
    setSingleAssignment,
    setSingleAssignee,
    removeSingleAssignee,
    pushNewComment
} = assignmentSlice.actions

export default assignmentSlice.reducer