import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STUDENT_INVOICES, STUDENT_INVOICES_API_URL, GET_ALL_STUDENT_INVOICES_API_URL, DOWNLOAD_INVOICE_PDF } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import { API_BASE_URL } from "constants/ApiConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  StudentInvoicesErrors: {},
  permission: permission?.student_invoices ?? [],
  StudentInvoicesShowMessage: null,
  StudentInvoicesResult: [],
  StudentInvoicesButtonSpinner: false,
  StudentInvoicesTableLoading: true,
  StudentInvoicesAddDrawer: false,
  StudentInvoicesEditData: [],
  StudentInvoicesButtonAndModelLabel: setLocale('studentinvoices.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewStudentInvoicesData: [],
  ViewStudentInvoicesLoader: true,
};

export const createStudentInvoices = createAsyncThunk(
  "createStudentInvoices",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, STUDENT_INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentInvoices = createAsyncThunk(
  "getStudentInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_STUDENT_INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewStudentInvoices = createAsyncThunk(
  "viewStudentInvoices",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, STUDENT_INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteStudentInvoices = createAsyncThunk(
  "deleteStudentInvoices",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, STUDENT_INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const downloadInvoice = createAsyncThunk(
  'downloadInvoice',
  async (filename) => {
    try {
      const response = await CommonService.getAllPost(filename, DOWNLOAD_INVOICE_PDF);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageStudentInvoicesSlice = createSlice({
  name: STUDENT_INVOICES,
  initialState,
  reducers: {
    StudentInvoicesAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.StudentInvoicesButtonSpinner = false;
      }
      state.StudentInvoicesErrors = {};
      state.StudentInvoicesAddDrawer = action.payload;
      state.StudentInvoicesEditData = [];
      state.StudentInvoicesButtonAndModelLabel = setLocale('studentinvoices.add');
    },
    StudentInvoicesEditWithDrawerStatus: (state, action) => {
      state.StudentInvoicesErrors = {};
      state.StudentInvoicesAddDrawer = true;
      state.StudentInvoicesEditData = action.payload;
      state.StudentInvoicesButtonAndModelLabel = setLocale('studentinvoices.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    videPDF(state, action) {
      const dataToPass = {
        id: action.payload.enc_id
      };

      // Constructing the query string from the data
      const queryString = Object.keys(dataToPass)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(dataToPass[key])}`)
        .join('&');

      // Constructing the final URL with the query string
      const url = `${API_BASE_URL}/view-student-invoice?${queryString}`;

      // Opening a new window with the specified URL
      window.open(url, '_blank');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentInvoices.pending, (state, action) => {
        state.StudentInvoicesButtonSpinner = true;
      }).addCase(createStudentInvoices.fulfilled, (state, action) => {
        state.StudentInvoicesButtonSpinner = false;
        state.StudentInvoicesErrors = {}
      }).addCase(createStudentInvoices.rejected, (state, action) => {
        state.StudentInvoicesShowMessage = true;
        state.StudentInvoicesButtonSpinner = false;
        state.StudentInvoicesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getStudentInvoices.pending, (state, action) => {
        state.StudentInvoicesButtonSpinner = true;
        state.StudentInvoicesTableLoading = true;
      }).addCase(getStudentInvoices.fulfilled, (state, action) => {
        state.StudentInvoicesButtonSpinner = false;
        state.StudentInvoicesTableLoading = false;
        state.StudentInvoicesResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      }).addCase(getStudentInvoices.rejected, (state, action) => {
        state.StudentInvoicesShowMessage = true; // Set the showMessage flag to display the errors
        state.StudentInvoicesButtonSpinner = false;
        state.StudentInvoicesTableLoading = false;
        state.StudentInvoicesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewStudentInvoices.pending, (state, action) => {
        state.ViewStudentInvoicesLoader = true;
        state.ViewStudentInvoicesData = [];
      }).addCase(viewStudentInvoices.fulfilled, (state, action) => {
        state.ViewStudentInvoicesLoader = false;
        state.ViewStudentInvoicesData = action.payload;
      }).addCase(viewStudentInvoices.rejected, (state, action) => {
        state.ViewStudentInvoicesLoader = false;
        state.ViewStudentInvoicesData = [];
      })
      .addCase(deleteStudentInvoices.pending, (state, action) => {

      }).addCase(deleteStudentInvoices.fulfilled, (state, action) => {

      }).addCase(deleteStudentInvoices.rejected, (state, action) => {

      })
      .addCase(downloadInvoice.pending, (state, action) => {
        state.InvoiceDownloadSpinner = true;
      }).addCase(downloadInvoice.fulfilled, (state, action) => {
        state.InvoiceDownloadSpinner = false;

        // Open the file in a new window
        // window.open(action.payload, '_blank');

        // OR, trigger a download
        window.location.href = action.payload;

      }).addCase(downloadInvoice.rejected, (state, action) => {
        state.InvoiceDownloadSpinner = false;
      });
  },
});

export const { videPDF, setColumnSearch, StudentInvoicesAddDrawerStatus, StudentInvoicesEditWithDrawerStatus, updateSortFilters } = manageStudentInvoicesSlice.actions;

export default manageStudentInvoicesSlice.reducer;
