import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  MANAGE_CLASS,
  GET_CLASS_COURSE_SCHEDULES,
} from "constants/AppConstants";
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from "services/CommonService/CommonService";
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(
  localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE)
);

export const initialState = {
  ClassCourseSchedulesData: [],
  classCourseSchedulesLoader: false,
  permission: permission?.manage_class ?? [],
};

export const getClassCourseSchedules = createAsyncThunk(
  "getClassCourseSchedules",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_CLASS_COURSE_SCHEDULES
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageManageClassSlice = createSlice({
  name: MANAGE_CLASS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getClassCourseSchedules.pending, (state, action) => {
        state.classCourseSchedulesLoader = true;
      })
      .addCase(getClassCourseSchedules.fulfilled, (state, action) => {
        state.classCourseSchedulesLoader = false;
        state.ClassCourseSchedulesData = action.payload;
      })
      .addCase(getClassCourseSchedules.rejected, (state, action) => {
        state.classCourseSchedulesLoader = false;
      });
  },
});

export const { ClassCourseScheduleDrawerStatus } =
  manageManageClassSlice.actions;

export default manageManageClassSlice.reducer;
