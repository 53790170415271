import React, { lazy } from 'react';
import LazyLoader from '../components/Admin/LazyLoader';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'login-1',
        path: `${AUTH_PREFIX_PATH}/login-1`,
        component: lazy(() => import('views/auth-views/authentication/login-1')),
    },
    {
        key: 'login-2',
        path: `${AUTH_PREFIX_PATH}/login-2`,
        component: lazy(() => import('views/auth-views/authentication/login-2')),
    },
    {
        key: 'register-1',
        path: `${AUTH_PREFIX_PATH}/register-1`,
        component: lazy(() => import('views/auth-views/authentication/register-1')),
    },
    {
        key: 'register-2',
        path: `${AUTH_PREFIX_PATH}/register-2`,
        component: lazy(() => import('views/auth-views/authentication/register-2')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: "auth.reset-password",
        path: `${AUTH_PREFIX_PATH}/reset-password/:id/:email`,
        component: React.lazy(() =>
            import("views/auth-views/components/ForgotPassword/resestPassword")
        ),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'error-page-2',
        path: `${AUTH_PREFIX_PATH}/error-page-2`,
        component: lazy(() => import('views/auth-views/errors/error-page-2')),
    },

]


let protectedRoutes = [];

const routesString = localStorage.getItem('protectedRoutes');
if (routesString) {
    const allprotectedRoutes = JSON.parse(routesString);

    protectedRoutes = allprotectedRoutes.map((route, index) => {
        const file_path = route.component;
        return {
            key: route.key,
            path: route.path,
            component: () => <LazyLoader filePath={file_path} key={index} />,
        };
    });
}

export { protectedRoutes };
