import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// NEED TO REMOVE THIS LATER
import { 
  STUDENT_ATTENDANCE_API_URL, 
  GET_ALL_STUDENT_ATTENDANCE_API_URL
} from 'constants/AppConstants';
// END NEED TO REMOVE THIS LATER
import {
  STUDENT_ATTENDANCE,
  GUARDIAN_STUDENT_ATTENDANCE,
  ATTENDANCE_PARENT_DASHBOARD
} from "constants/studentAttendance/index";
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  StudentAttendanceErrors       : {},
  permission: permission?.student_attendance ?? [],
  StudentAttendanceShowMessage  : null,

  monthWiseAttendanceLoader: false,
  monthWiseAttendance: [],

  attendanceDashboardLoader: false,
  attendanceDashboardReport:{},
  attendanceClassBar:{},
  attendanceWeeklyArea:{},
  attendanceStudentAttentions:[],


  // NEED TO REMOVE THIS LATER
  // StudentAttendanceResult       : [],
  // StudentAttendanceButtonSpinner : false,
  // StudentAttendanceTableLoading : true,
  // StudentAttendanceAddDrawer    : false,
  // StudentAttendanceEditData     : [],
  // StudentAttendanceButtonAndModelLabel  : setLocale('studentattendance.add'),
  // tablePagination: {
  //       current: 1,
  //       pageSize: 10,
  //       total: 0,
  //   },
  //   sorting: {},
  //   filter: {},
  //   ViewStudentAttendanceData: [],
  //   ViewStudentAttendanceLoader: true,
    // END NEED TO REMOVE THIS LATER
    
};
export const guardianStudentsAttendance = createAsyncThunk("guardianStudentsAttendance", async (data) => {
  try {
    return await CommonService.getData(data, GUARDIAN_STUDENT_ATTENDANCE);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getAttendanceParentDashBoard = createAsyncThunk('getAttendanceParentDashBoard', async (data) => {
	try {
		return CommonService.postData(data,ATTENDANCE_PARENT_DASHBOARD)
	} catch (err) {
		throw new Error(JSON.stringify(err.response.data.errors))
	}
});

// NEED TO REMOVE THIS LATER
// export const createStudentAttendance = createAsyncThunk("createStudentAttendance", async (data) => {
//     try {
//       const response = await CommonService.createAndUpdate(data, STUDENT_ATTENDANCE_API_URL);
//       return response;
//     } catch (err) {
//       throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
//     }
// });

// export const getStudentAttendance = createAsyncThunk("getStudentAttendance", async (data) => {
//     try {
//       const response = await CommonService.getAllPost(data, GET_ALL_STUDENT_ATTENDANCE_API_URL);
//       return response;
//     } catch (err) {
//       throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
//     }
// });

// export const viewStudentAttendance = createAsyncThunk("viewStudentAttendance", async (id) => {
//     try {
//       const response = await CommonService.showOne(id, STUDENT_ATTENDANCE_API_URL);
//       return response;
//     } catch (err) {
//       throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
//     }
// });

// export const deleteStudentAttendance = createAsyncThunk( "deleteStudentAttendance", async (data) => {
//     try {
//       const response = await CommonService.deleteOne(data, STUDENT_ATTENDANCE_API_URL);
//       return response;
//     } catch (err) {
//       throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
//     }
// });
// END NEED TO REMOVE THIS LATER

export const manageStudentAttendanceSlice = createSlice({
  name: STUDENT_ATTENDANCE,
  initialState,
  reducers: {



    // NEED TO REMOVE THIS LATER
    // StudentAttendanceAddDrawerStatus: (state, action) => {
    //   state.StudentAttendanceErrors      = {};
    //   state.StudentAttendanceAddDrawer   = action.payload;
    //   state.StudentAttendanceEditData    = [];
    //   state.StudentAttendanceButtonAndModelLabel = setLocale('studentattendance.add');
    // },
    // StudentAttendanceEditWithDrawerStatus: (state, action) => {
    //   state.StudentAttendanceErrors = {};
    //   state.StudentAttendanceAddDrawer = true;
    //   state.StudentAttendanceEditData = action.payload;
    //   state.StudentAttendanceButtonAndModelLabel = setLocale('studentattendance.edit');
    // },
    // updateSortFilters: (state, action) => {
    //     state.filter = action.payload.filter;
    //     state.sorting = action.payload.sorting;
    // },
    // END NEED TO REMOVE THIS LATER
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttendanceParentDashBoard.pending, (state, action) => {
        state.attendanceDashboardLoader = true
        state.attendanceDashboardReport = {}
        state.attendanceClassBar = {}
        state.attendanceWeeklyArea = {}
        state.attendanceStudentAttentions = []
      }).addCase(getAttendanceParentDashBoard.fulfilled, (state, action) => {
        state.attendanceDashboardReport = action.payload.data.attendanceCards
        state.attendanceClassBar = action.payload.data.attendanceClassBar
        state.attendanceWeeklyArea = action.payload.data.attendanceWeeklyArea
        state.attendanceStudentAttentions = action.payload.data.attendanceStudentAttention
        state.attendanceDashboardLoader = false
      }).addCase(getAttendanceParentDashBoard.rejected, (state, action) => {
        state.attendanceDashboardLoader = false        
      })
      .addCase(guardianStudentsAttendance.pending, (state, action) => {
        state.monthWiseAttendanceLoader = true
        state.monthWiseAttendance = [];
      }).addCase(guardianStudentsAttendance.fulfilled, (state, action) => {
        state.monthWiseAttendanceLoader = false
        state.monthWiseAttendance = action.payload.attendance
      }).addCase(guardianStudentsAttendance.rejected, (state, action) => {
        state.monthWiseAttendanceLoader = false
      })
    
      // NEED TO REMOVE THIS LATER
      // .addCase(createStudentAttendance.pending, (state, action) => {
      //   state.StudentAttendanceButtonSpinner = true;
      // }).addCase(createStudentAttendance.fulfilled, (state, action) => {
      //   state.StudentAttendanceButtonSpinner = false;
      //   state.StudentAttendanceErrors = {}
      // }).addCase(createStudentAttendance.rejected, (state, action) => {
      //   state.StudentAttendanceShowMessage = true;
      //   state.StudentAttendanceButtonSpinner = false;
      //   state.StudentAttendanceErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      // }).addCase(getStudentAttendance.pending, (state, action) => {
      //   state.StudentAttendanceButtonSpinner = true;
      //   state.StudentAttendanceTableLoading = true;
      // }).addCase(getStudentAttendance.fulfilled, (state, action) => {
      //   state.StudentAttendanceButtonSpinner = false;
      //   state.StudentAttendanceTableLoading = false;
      //   state.StudentAttendanceResult = action.payload;
      //   state.tablePagination = {
      //       ...state.tablePagination,
      //       total: action.payload.total,
      //       current: action.payload.current_page,
      //       pageSize: action.payload.per_page
      //   };
      // }).addCase(getStudentAttendance.rejected, (state, action) => {
      //   state.StudentAttendanceShowMessage = true; // Set the showMessage flag to display the errors
      //   state.StudentAttendanceButtonSpinner = false;
      //   state.StudentAttendanceTableLoading = false;
      //   state.StudentAttendanceErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      // })
      // .addCase(viewStudentAttendance.pending, (state, action) => {
      //   state.ViewStudentAttendanceLoader = true;
      //   state.ViewStudentAttendanceData = [];
      // }).addCase(viewStudentAttendance.fulfilled, (state, action) => {
      //   state.ViewStudentAttendanceLoader = false;
      //   state.ViewStudentAttendanceData = action.payload;
      // }).addCase(viewStudentAttendance.rejected, (state, action) => {
      //   state.ViewStudentAttendanceLoader = false;
      //   state.ViewStudentAttendanceData = [];
      // })
      // .addCase(deleteStudentAttendance.pending, (state, action) => {
      // }).addCase(deleteStudentAttendance.fulfilled, (state, action) => {
      // }).addCase(deleteStudentAttendance.rejected, (state, action) => {
      // });
      // END NEED TO REMOVE THIS LATER
  },
});

export const {  
  // StudentAttendanceAddDrawerStatus, 
  // StudentAttendanceEditWithDrawerStatus, 
  // updateSortFilters 
} = manageStudentAttendanceSlice.actions;

export default manageStudentAttendanceSlice.reducer;
