import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {ABSENT_REASON, ABSENT_REASON_API_URL, GET_ALL_ABSENT_REASON_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  AbsentReasonErrors       : {},
  permission: permission?.absent_reason ?? [],
  AbsentReasonShowMessage  : null,
  AbsentReasonResult       : [],
  AbsentReasonButtonSpinner : false,
  AbsentReasonTableLoading : true,
  AbsentReasonAddDrawer    : false,
  AbsentReasonEditData     : [],
  AbsentReasonButtonAndModelLabel  : setLocale('absentreason.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewAbsentReasonData: [],
    ViewAbsentReasonLoader: true,
};


export const getAbsentReason = createAsyncThunk(
    "getAbsentReason",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ABSENT_REASON_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);



export const manageAbsentReasonSlice = createSlice({
  name: ABSENT_REASON,
  initialState,
  reducers: {
    AbsentReasonAddDrawerStatus: (state, action) => {
      state.AbsentReasonErrors      = {};
      state.AbsentReasonAddDrawer   = action.payload;
      state.AbsentReasonEditData    = [];
      state.AbsentReasonButtonAndModelLabel = setLocale('absentreason.add');
    },
    AbsentReasonEditWithDrawerStatus: (state, action) => {
      state.AbsentReasonErrors = {};
      state.AbsentReasonAddDrawer = true;
      state.AbsentReasonEditData = action.payload;
      state.AbsentReasonButtonAndModelLabel = setLocale('absentreason.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAbsentReason.pending, (state, action) => {
        state.AbsentReasonButtonSpinner = true;
        state.AbsentReasonTableLoading = true;
      }).addCase(getAbsentReason.fulfilled, (state, action) => {
        state.AbsentReasonButtonSpinner = false;
        state.AbsentReasonTableLoading = false;
        state.AbsentReasonResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getAbsentReason.rejected, (state, action) => {
        state.AbsentReasonShowMessage = true; // Set the showMessage flag to display the errors
        state.AbsentReasonButtonSpinner = false;
        state.AbsentReasonTableLoading = false;
        state.AbsentReasonErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
  },
});

export const {  AbsentReasonAddDrawerStatus, AbsentReasonEditWithDrawerStatus, updateSortFilters } = manageAbsentReasonSlice.actions;

export default manageAbsentReasonSlice.reducer;
